const CIRCLE_COLORS = [
  "#000000",
  "#ff0000",
  "#ff8c00",
  "#ffff00",
  "#008000",
  "#0000ff",
  "#4b0082",
  "#800080",
];

export default CIRCLE_COLORS;
