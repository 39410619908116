const PAGE_COLORS = {
  MAIN_COLOR: "#B9B8AC",
  FONT_COLOR: "#000000",
  MAIN_BACKGROUND: "#B9B8AC",
  ERROR_BACKGROUND: "#f2f5f8",
  ERROR_CONTENT: "#757575",
  BUTTON_BACKGROUND: "#0000",
  BUTTON_FONT_CHANGE_COLOR: "#fff",
  MODAL_COLOR: "#fff",
  MODAL_BACKGROUND: "rgba(0, 0, 0, 0.55)",
  LOADING_COLOR: "#f5df4d",
};

export default PAGE_COLORS;
