const HANDS_ID = [
  {
    id: "originCanvas",
  },
  {
    id: "rightCanvas",
  },
  {
    id: "rightSubCanvas",
  },
  {
    id: "leftCanvas",
  },
  {
    id: "leftSubCanvas",
  },
];

export default HANDS_ID;
